import React from 'react'
import {sanitizeFilename} from '../lib/helpers'
import { FaArrowRight, FaExclamationCircle } from 'react-icons/fa'
import FileIcon from './fileIcon'

const FileComponent = ({item}) => {

    if(item.file && item.file.asset){
        return <><FileIcon fileUrl={item.file.asset.url} /> <a href={item.file && item.file.asset ? item.file.asset.url +'/'+ sanitizeFilename(item.file.asset.originalFilename) : '#'} target='_blank'>{item.title}</a></>
    }
    else{
        return <>{item.title}</> // <FaExclamationCircle size={22} />
    }
}

export default FileComponent