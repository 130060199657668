import React, {useState, useEffect} from 'react'
import {navigate, Link} from 'gatsby'
import Select from 'react-select'
import {isUserInRegion} from '../services/auth'
import {sanitizeFilename} from '../lib/helpers'


const DropdownSelect = ({items, userRegion}) => {

    const [options, setOptions] = useState([])

    useEffect(() => {
        let filteredItem = items && items.filter(item => isUserInRegion(userRegion, item.region))
        const _options = filteredItem ? filteredItem.map(item => {
            if(item._type === 'link'){
                return  { value: item.url, label: item.title, type: 'link' }
            }
            else{
                let url = item.file && item.file.asset ? item.file.asset.url +'/'+ sanitizeFilename(item.file.asset.originalFilename) : '#'
                return  { value: url, label: item.title, type: 'file' }
            }
        }) : []

        setOptions(_options)
    }, [])

    const handleChange = selectedOption => {
        if(selectedOption.type === 'link'){
            navigate(selectedOption.value) 
        }
        else{
            window.open(selectedOption.value, '_blank')
        }
    }

    // return (
    //     <>
    //         {options.length > 0 && (
    //             <Select onChange={handleChange} options={options} />
    //         )}
    //     </>
    // )

    // {console.log('---> items > link: ', options)}

    return (
        <>
            {
                (() => {
                    if(options.length === 1){
                        if(options[0].type === 'link'){
                            return <Link to={options[0].value || '#'} className="btn btn-primary" style={{float: 'right'}} >GO</Link>
                        }
                        else{
                            return <a href={options[0].value || '#'} target='_blank' className="btn btn-primary" style={{float: 'right'}} >GO</a>
                        }
                    }
                    else if(options.length > 1){
                        return <Select onChange={handleChange} options={options} />
                    }
                })()
            }
        </>
    )

}

export default DropdownSelect