import React from 'react'
import { FaArrowRight, FaFilePdf, FaFileWord, FaFileArchive, FaFileExcel, FaFilePowerpoint, FaFileVideo, FaFileImage } from 'react-icons/fa'

const FileIcon = ({fileUrl, style}) => {

    let _icon
    if(fileUrl){
        let _type = fileUrl.split('.')
        switch (_type[_type.length-1]) {
            case 'pdf':
                _icon = <FaFilePdf size={22} color="#515155" style={style} />
                break;
            case 'rar':
            case 'zip':
                _icon = <FaFileArchive size={22} color="#515155" style={style} />
                break;
            case 'doc':
            case 'docx':
                _icon = <FaFileWord size={22} color="#515155" style={style} />
                break;
            case 'xls':
            case 'xlsx':
                _icon = <FaFileExcel size={22} color="#515155" style={style} />
                break;
            case 'mp4':
                _icon = <FaFileVideo size={22} color="#515155" style={style} />
                break;
            case 'png':
            case 'jpg':
            case 'jpeg':
                _icon = <FaFileImage size={22} color="#515155" style={style} />
                break;
                
            default:
                _icon = ''
                break;
        }
    }


    return _icon

}

export default FileIcon