import React, {useState, useEffect} from 'react'
import {sanitizeFilename} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import DropdownSelect from './dropdownSelect'
import PortableText from '../components/portableText'
import { FaArrowRight, FaExclamationCircle } from 'react-icons/fa'
import {Link} from 'gatsby'
import {isUserInRegion} from '../services/auth'
import FileIcon from './fileIcon'
import FileComponent from './fileComponent'

const AccordionLinkFile = ({items, userRegion}) => {

    let filteredItem = items.filter(item => isUserInRegion(userRegion, item.region))

    // console.log('---> filter item: ', filteredItem)

    return (
        <ul className="list-group list-group-borderless list-group-icon-primary-bg me-md-6">
            {
                filteredItem &&
                filteredItem.map((item, i) => {
                    return (
                        <li key={i} className="list-group-item">
                            {
                                item._type === 'link' && <><FaArrowRight size={22} /> <Link to={item.url || '#'}>{item.title}</Link></>
                            }

                            {/* {
                                item._type === 'fileComponent' && item.file && item.file.asset && (<><FileIcon fileUrl={item.file.asset.url} /> <a href={item.file && item.file.asset ? item.file.asset.url +'/'+ sanitizeFilename(item.file.asset.originalFilename) : '#'} target='_blank'>{item.title}</a></>)
                            } */}                            
                            {
                                item._type === 'fileComponent' && <FileComponent item={item} />
                            }
                        </li>                        
                    )
                })
            }
        </ul>
    )
}

export default AccordionLinkFile